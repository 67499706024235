<template>
  <b-container class="big-module-training">
       <div class="free-title"><span>自由模式</span></div>
    <b-row class="page-top">
      <b-col class="text-left">
        <span class="iconfont icon-tishi"></span>
        你当前正在自由模式下训练，可选择任何模块进行学习！
      </b-col>
    </b-row>
    <b-row class="page-header">
      <b-col></b-col>
      <b-col>七年级</b-col>
      <b-col>八年级</b-col>
      <b-col>九年级</b-col>
      <b-col>预期时间</b-col>
      <b-col>实际时间</b-col>
    </b-row>
    <b-col
      :class="
        item.vocabularyTrainings.length < 1 ? 'page-text' : 'page-content'
      "
      v-for="(item, index) in bigModuleTrainings"
      :key="index"
    >
      <b-row class="title-table">
        <b-col sm="2" class="question-title">
          <div class="question-type">{{ item.sectionName }}</div>
        </b-col>
        <b-col
          v-show="item.vocabularyTrainings.length > 1 ? false : true"
          v-for="(items, c) in item.vocabularyTrainings"
          :key="c"
        >
          <b-row align-v="center">
            <b-col>
              <!-- 七年级 -->
              <GlobalButtonDirectory
                :state="items.seven.state"
                :starNum="items.seven.star"
                @beginTest="
                  toTrainingQuestions(
                    item.sectionType,
                    items.questionSet,
                    items.seven,
                    item.sectionName,
                    items.trainingExamId
                  )
                "
              >
              </GlobalButtonDirectory>
            </b-col>
            <b-col>
              <!-- 八年级 -->
              <GlobalButtonDirectory
                :state="items.eighth.state"
                :starNum="items.eighth.star"
                @beginTest="
                  toTrainingQuestions(
                    item.sectionType,
                    items.questionSet,
                    items.eighth,
                    item.sectionName,
                    items.trainingExamId
                  )
                "
              >
              </GlobalButtonDirectory>
            </b-col>
            <b-col>
              <!-- 九年级 -->
              <GlobalButtonDirectory
                :state="items.nine.state"
                :starNum="items.nine.star"
                @beginTest="
                  toTrainingQuestions(
                    item.sectionType,
                    items.questionSet,
                    items.nine,
                    item.sectionName,
                    items.trainingExamId
                  )
                "
              >
              </GlobalButtonDirectory>
            </b-col>
            <b-col class="estimated-time"
              >{{ items.expectedMinutes }}分钟</b-col
            >
            <b-col class="actual-time">{{ items.actualMinutes }}分钟</b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="sub-content">
        <b-row
          v-show="item.vocabularyTrainings.length < 2 ? false : true"
          v-for="(items, c) in item.vocabularyTrainings"
          :key="c"
        >
          <b-col sm="2" class="read-title">
            <div>{{ items.trainingExamName }}</div>
          </b-col>
          <b-col>
            <!-- 子组件七年级 -->
            <GlobalButtonDirectory
              :state="items.seven.state"
              :starNum="items.seven.star"
              @beginTest="
                toTrainingQuestions(
                  item.sectionType,
                  items.questionSet,
                  items.seven,
                  item.sectionName,
                  items.trainingExamId
                )
              "
            >
            </GlobalButtonDirectory>
          </b-col>
          <b-col>
            <!-- 子组件八年级 -->
            <GlobalButtonDirectory
              :state="items.eighth.state"
              :starNum="items.eighth.star"
              @beginTest="
                toTrainingQuestions(
                  item.sectionType,
                  items.questionSet,
                  items.eighth,
                  item.sectionName,
                  items.trainingExamId
                )
              "
            >
            </GlobalButtonDirectory>
          </b-col>
          <b-col>
            <!-- 子组件九年级 -->
            <GlobalButtonDirectory
              :state="items.nine.state"
              :starNum="items.nine.star"
              @beginTest="
                toTrainingQuestions(
                  item.sectionType,
                  items.questionSet,
                  items.nine,
                  item.sectionName,
                  items.trainingExamId
                )
              "
            >
            </GlobalButtonDirectory>
          </b-col>
          <b-col  class="estimated-time1">{{ items.expectedMinutes }}分钟</b-col>
          <b-col class="actual-time1">{{ items.actualMinutes }}分钟</b-col>
        </b-row>
      </div>
    </b-col>
  </b-container>
</template>

<script>

import { getBigModuleFreeTraining } from "@/api/english-big";
import GlobalButtonDirectory from "@/components/global-button/global-button-directory.vue";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      bigModuleTrainings: [],
      vocabularyTrainings: [],
    };
  },
  props: {
    sprintProgress: {
      default: 1,
    },
  },
  components: {

    GlobalButtonDirectory,
  },
  mounted() {
    this.getSections();
  },
  methods: {
    ...mapMutations(["setTrainingData"]),
    getSections() {
      getBigModuleFreeTraining({}).then((res) => {
        console.log("靶向训练页", res);
        this.bigModuleTrainings = res.data.result.bigModuleTrainings;
      });
    },

    toTrainingQuestions(
      sectionType,
      questionSet,
      difficulty,
      sectionName,
      trainingExamId
    ) {
      let trainingData = {
        tagId: sectionType,
        setTagId: questionSet,
        difficultyTagId: difficulty.tagId,
        summaryId: difficulty.summaryId,
        trainingExamId: trainingExamId,
      };
      console.log("trainingData缓存", trainingData);
      this.setTrainingData(trainingData);
      this.$router.push({
        name: "BigModuleTrainingQuestions",
        query: {
          type: JSON.stringify(sectionType),
          // module: 2,
          name: sectionName,
          mode:'free'
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.free-title {
  width: 172px;
  height: 49px;
  background: #2CB774;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  font-size: 30px;
  color: white;
  font-weight: bold;
  transform: skewX(-30deg);
  position: absolute;
  display: flex;
  top: -80px;
  span {
    width: 100%;
    position: absolute;
    transform: skewX(30deg);
  }
}
.big-module-training {
  background: $bg-white;
  max-width: 1280px;
  min-height: 960px;
  padding: 20px 20px 50px;
  position: relative;
}
.page-top {
  margin: 10px 0;
}
.text-left {
  color: $text-green;
  font-weight: bold;
}
.icon-suo1beifen {
  padding-right: 7px;
  font-size: 14px;
}
.page-header {
  border: 1px solid #e5e5e5;
  background-color: $bg-green;
  margin: 0 auto;
  width: 98%;
  color: #fff;
  padding: 10px 0;
  font-size: 16px;
}
.page-content {
  border-bottom: 1px solid #e5e5e5;
  margin: 0 auto;
  width: 98%;
  // padding: 10px 0;
}
.question-type {
  background: #ffc13b;
  width: 45%;
  line-height: 2.5;
  color: $text-white;
  border-radius: 5px;
}
.btn-suo {
  background: $bg-white;
  border: 1px solid #999;
  border-radius: 5px;
  color: #999;
  width: 50%;
  line-height: 1.5;
}
.estimated-time,
.estimated-time1,
.actual-time,
.actual-time1 {
  color: #999;
}
.read-title {
  text-align: left;
  padding-left: 45px;
}
.page-content .title-table {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
}
.sub-content .row:nth-child(odd) {
  background: #eef9f4;
}
.sub-content .row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  margin: 0;
}
.page-text {
  border: none;
  margin: 0 auto;
  width: 98%;
  padding: 10px 0;
  .title-table {
    width: 100%;
    margin: 0 auto;
  }
  .sub-content {
    padding-top: 1rem;
  }
}
.question-title {
  padding-left: 0;
}
</style>
<style lang="scss">
.page-content  {
  .button {
    .col {
      padding: 1rem 0.8rem;
    }
  }
}
</style>